/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Link, useNavigate } from "react-router-dom";
import { AizzyLogo } from "../../AizzyLogo";
import ButtonType1 from "../../Buttons/ButtonType1";
import COLORS from "../../../utils/constants/colors";
import FlagBrSvg from "../../../assets/images/icons/survey/flag-br.svg";
import FlagEsSvg from "../../../assets/images/icons/survey/flag-es.svg";
import FlagUsSvg from "../../../assets/images/icons/survey/flag-eus.svg";
import { useSelector } from "react-redux";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import { IconMenu2 } from "@tabler/icons-react";
import { LandingPageI18n } from "./LandingPageTranslactions";
import UseTranslation from "../../../hooks/useTranslation";
import MenuMobile from "./MenuMobile";
import { useEffect, useState } from "react";
import { Select } from "antd";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { toast } from "sonner";
import i18next from "i18next";
import { showErrorNotification } from "../../../services/notifications";
import detectBrowserLanguage from "detect-browser-language";

const HeaderLandingPage = () => {
  const state = useSelector(state => state);
  const [openMenuMobile, setOpenMenuMobile] = useState(false);
  const { t } = UseTranslation(LandingPageI18n);
  const supabaseClient = useSupabaseClient();
  const navigate = useNavigate();
  const [language, setLanguage] = useState();

  const lang = detectBrowserLanguage();

  const languageOptions = [
    { code: "en-US", name: "US", flag: FlagUsSvg },
    { code: "pt-BR", name: "PT", flag: FlagBrSvg },
    { code: "es-ES", name: "ES", flag: FlagEsSvg },
  ];

  const getCurrentLanguage = () => {
    const savedConfig = localStorage.getItem("configsGeneralReducer");
    if (savedConfig) {
      const { language } = JSON.parse(savedConfig);
      return (
        languageOptions.find(lang => lang.code === language)?.name ||
        lang ||
        "US"
      );
    }
    return "US";
  };

  useEffect(() => {
    setLanguage(getCurrentLanguage());
  }, []);

  const handleLanguageChange = async selectedLanguage => {
    const selectedOption = languageOptions.find(
      lang => lang.name === selectedLanguage
    );
    setLanguage(selectedLanguage);

    try {
      localStorage.setItem(
        "configsGeneralReducer",
        JSON.stringify({ language: selectedOption.code })
      );

      toast.loading("Updating language...");
      await supabaseClient.auth.updateUser({
        data: { language: selectedOption.code },
      });

      await supabaseClient.auth.refreshSession();
      i18next.changeLanguage(selectedOption.code);
      window.location.reload();
    } catch (error) {
      console.error(error || "Error updating language");
      showErrorNotification("Error updating language");
    }
  };

  const breakpointLg =
    state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;

  const navigationLinks = [
    { title: t("nav.about"), link: "https://github.com/aizzy-ai" },
    {
      title: t("nav.capabilities"),
      link: "https://app.uniswap.org/explore/tokens/base/0xc51d94a9936616b90e26abe61921ab3b4e66a149",
    },
    { title: t("nav.docs"), link: "https://github.com/aizzy-ai" },
    { title: "FAQ", link: "#faqs" },
  ];

  return (
    <>
      <section
        className="d-flex justify-content-center align-items-center"
        style={{
          position: "fixed",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          width: breakpointLg ? "100%" : "calc(100% - 142px)",
          height: "130px",
          zIndex: 100,
        }}
      >
        <nav
          className="w-100 d-flex justify-content-between align-items-center"
          style={{
            border: `1px solid ${COLORS.gray100}`,
            background: COLORS.white,
            borderRadius: 24,
            padding: "24px",
            maxWidth: breakpointLg ? "calc(100% - 32px)" : 1280,
          }}
        >
          <AizzyLogo
            width={breakpointLg ? 83 : 111}
            height={breakpointLg ? 24 : 32}
          />

          {!breakpointLg && (
            <ul className="d-flex gap-3">
              {navigationLinks.map(item => (
                <li key={item.title} className="font-size-14">
                  <Link
                    style={{ color: COLORS.gray500 }}
                    to={item.link}
                    target={item.title === "FAQ" ? "_self" : "_blank"}
                    onClick={() => {
                      if (item.title === "FAQ") {
                        const section = document.getElementById("faqs");
                        if (section) {
                          section.scrollIntoView({ behavior: "smooth" });
                        }
                      }
                    }}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          )}

          <div className="d-flex gap-2 align-items-center">
            {!breakpointLg && (
              <Select
                className="select-language-antd"
                style={{
                  height: 40,
                  minWidth: 85,
                  fontSize: 14,
                }}
                value={
                  language || state?.configsGeneralReducer?.currentLanguage
                }
                onChange={handleLanguageChange}
              >
                {languageOptions.map(lang => (
                  <Select.Option
                    key={lang.code}
                    value={lang.name}
                    label={
                      <img
                        src={lang.flag}
                        alt={lang.name}
                        style={{ width: 20, height: 14 }}
                      />
                    }
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src={lang.flag}
                        alt={lang.name}
                        style={{ width: 18, height: 14, marginRight: 8 }}
                      />
                      <span className="font-size-14">{lang.name}</span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            )}

            <ButtonType1
              text={t("button")}
              props={{
                onClick: () => navigate("/"),
                disabled: false,
                style: {
                  borderRadius: "8px",
                  width: breakpointLg ? 110 : 127,
                  height: breakpointLg ? 32 : 40,
                },
              }}
            />

            {breakpointLg && (
              <ButtonType1
                props={{
                  disabled: false,
                  onClick: () => setOpenMenuMobile(!openMenuMobile),
                  style: {
                    borderRadius: "10px",
                    background: COLORS.gray100,
                    width: 32,
                    height: 32,
                  },
                }}
                variation="secondary"
                text={<IconMenu2 size={16} stroke={1.5} />}
              />
            )}
          </div>
        </nav>
      </section>
      {openMenuMobile && (
        <MenuMobile
          openMenuMobile={() => setOpenMenuMobile(!openMenuMobile)}
          breakpointLg={breakpointLg}
          state={state}
          language={language}
          languageOptions={languageOptions}
          handleLanguageChange={handleLanguageChange}
        />
      )}
    </>
  );
};

export default HeaderLandingPage;
