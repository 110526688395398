/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import CardAssistantsNewLayout from "../../Cards/CardAssistantsNewLayout";
import { getFreeCommunityAssistants } from "../../../api/assistants";
import { useQuery } from "react-query";
import { QUERY_KEYS, queryClient } from "../../../libs/ReactQuery";
import {
  IconChevronLeft,
  IconChevronRight,
  IconLoader2,
} from "@tabler/icons-react";
import { setConfigsMainChat } from "../../../redux/general/action";
import { KEYS_MAIN_CHAT } from "../../../services/chat/defaultConfigsMainChat";
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import COLORS from "../../../utils/constants/colors";
import { getCurrentUserLanguage } from "../../../services/userHelper";
import ModalAssistantInfo from "../../Modals/ModalInfoAssistants";
import { showRealoadNotification } from "../../../services/notifications";

const ListaAssistantsCommunity = () => {

  let showErro = false;
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [selectedAssistant, setSelectedAssistant] = useState(null);

  const userLanguage = getCurrentUserLanguage();

  const { data, isLoading, isFetching, error } = useQuery(
    [QUERY_KEYS.COMMUNITY_ASSISTANTS, page],
    () =>
      getFreeCommunityAssistants({
        pageNumber: page,
        language: userLanguage,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
    }
  );

  const assistantsCount =
    queryClient.getQueryData([QUERY_KEYS.COMMUNITY_ASSISTANTS, 0])
      ?.total_count || 0;

  const assistants = data?.assistants || [];

  const renderSkeletons = [...Array(20)].map((_, index) => (
    <Skeleton
      key={`skeleton-${index}`}
      baseColor={"#e4e4e7"}
      highlightColor={"#F1F1F1"}
      duration={1}
      height={240}
      borderRadius={16}
    />
  ));

  const handleCardClick = assistant => {
    setSelectedAssistant(assistant);
    setOpenModalInfo(true);
  };

  useEffect(() => {
      showRealoadNotification((error ? error?.message : null), showErro);
      showErro = true;
   }, [error]);

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex justify-content-between">
        <button
          className="fw-semibold d-flex align-items-center justify-content-center gap-1"
          style={{
            fontSize: 12,
            color: COLORS.gray500,
          }}
          type="button"
          disabled={page === 0 || isLoading || isFetching}
          onClick={() => setPage(prev => Math.max(prev - 1, 0))}
        >
          <IconChevronLeft size={16} />
          Previous page
        </button>
        <span className="font-size-12 d-flex align-items-center gap-1">
          Total assistants: {assistantsCount}
          {!isLoading && isFetching && (
            <IconLoader2 size={18} className="spin" />
          )}
        </span>
        <button
          className="fw-semibold d-flex align-items-center justify-content-center gap-1"
          style={{
            fontSize: 12,
            color: COLORS.gray500,
          }}
          type="button"
          disabled={assistants.length < 20 || isLoading || isFetching}
          onClick={() => setPage(prev => prev + 1)}
        >
          Next page
          <IconChevronRight size={16} />
        </button>
      </div>
      <div className="grid-vertical-assistants">
        {isLoading
          ? renderSkeletons
          : assistants && assistants?.map((assistant, index) => (
              <CardAssistantsNewLayout
                setOpenModalInfo={() => handleCardClick(assistant)}
                key={index}
                direction="vertical"
                image={assistant.profileImage}
                title={assistant.name}
                description={assistant.role}
                icon={assistant.profileImage}
                name={assistant.userName}
                assistant={assistant}
                onSelect={() =>
                  dispatch(
                    setConfigsMainChat(KEYS_MAIN_CHAT.assistant, assistant)
                  )
                }
              />
            ))}
      </div>
      <ModalAssistantInfo
        isOpen={openModalInfo}
        toggleModal={() => {
          setOpenModalInfo(false);
        }}
        assistant={selectedAssistant}
      />{" "}
    </div>
  );
};

export default ListaAssistantsCommunity;
